// For network: mainnet
export const CONTRACT_ADDRESSES = {
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  SeniorPool: "0x8481a6EbAf5c7DABc3F7e09e44A89531fd31F822",
  GFI: "0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b",
  Fidu: "0x6a445E9F40e0b97c92d0b8a3366cEF1d67F700BF",
  UniqueIdentity: "0xba0439088dc1e75F58e0A7C107627942C15cbb41",
  Go: "0x84AC02474c4656C88d4e08FCA63ff73070787C3d",
  Borrower: "0xE1635F4F0EEE83C5E24023Ac7f8B9F2079fdD7d6",
  StakingRewards: "0xFD6FF39DA508d281C2d255e9bBBfAb34B6be60c3",
  Zapper: "0xd7b528e749078daDeE2f0071cde6fca4e191A521",
  CommunityRewards: "0x0Cd73c18C085dEB287257ED2307eC713e9Af3460",
  MerkleDistributor: "0x0f306E3f6b2d5ae820d33C284659B29847972d9A",
  BackerMerkleDistributor: "0xC442b55A082f7D5F8d8DcDA3d0efF50f47dD0F82",
  MerkleDirectDistributor: "0x7766e86584069Cf5d1223323d89486e95d9a8C22",
  BackerMerkleDirectDistributor: "0xE75b8E4da218f21cf0F0CE967ac35276A10aAbDB",
  BackerRewards: "0x384860F14B39CcD9C89A73519c70cD5f5394D0a6",
  WithdrawalRequestToken: "0xc84D4a45d1d7EB307BBDeA94b282bEE9892bd523",
  CurvePool: "0x80aa1a80a30055daa084e599836532f3e58c95e2",
  CurveLP: "0x42ec68ca5c2c80036044f3eead675447ab3a8065",
  PoolTokens: "0x57686612C601Cb5213b01AA8e80AfEb24BBd01df",
  MembershipOrchestrator: "0x4E5d9B093986D864331d88e0a13a616e1D508838",
  MembershipVault: "0x375B906B25E00bdD43017400CD4cefb36FBF9c18",
  ERC20Splitter: "0xE2da0Cf4DCEe902F74D4949145Ea2eC24F0718a4",
  GoldfinchConfig: "0xaA425F8BfE82CD18f634e2Fe91E5DdEeFD98fDA1",
};
