export enum VerificationFlowSteps {
  StatusCheck,
  Intro,
  IndividualOrEntity,
  Residence,
  IdIssuer,
  Accredited,
  IdWarning,
  Persona,
  ParallelMarkets,
  Pending,
  Mint,
  MintToAddress,
  MintFinished,
  Ineligible,
  AlreadyMinted,
}
